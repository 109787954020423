@import "src/styles/breakpoints"

.wrapper
  position: relative
  z-index: 10

.container
  display: flex
  padding-left: 35px
  padding-right: 15px
  align-items: flex-end
  width: calc(100% + 14px)
  margin-left: -7px
  margin-right: -7px

.select
  flex-grow: 1
  flex-basis: 0
  padding: 10px 0px 30px 0px

.search 
  padding-top: 15px
  margin-top: 20px
  padding-left: 15px

.button
  padding-right: 30px
  padding-left: 5px
  margin-left: 10px
  width: 44px
  align-self: center
  height: 44px
  display: flex
  align-items: center
  margin-bottom: 1px
  svg
    width: 20px
    height: 20px
    path
      fill: var(--wt-blue3-dt-blue12)

@include media('<=1330px')
  .container
    flex-wrap: wrap
  .select
    width: 33.333%
    flex-basis: 33.333%
    box-sizing: border-box

@include media('<=tablet')
  .container
    padding: 30px 25px