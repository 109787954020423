@import "src/styles/variables"
@import "src/styles/breakpoints"

.wrapper
  display: flex
  flex-direction: column
  padding-top: 50px
  align-items: center
  margin: 0 auto
  @include media('<=Lphone')
    max-width: 360px
  p
    font-weight: bold
    font-size: 30px
    line-height: 1.4
    margin: 0
    margin-bottom: 30px
    text-align: center
    @include media('<=Lphone')
      font-size: 24px
      max-width: 400px
