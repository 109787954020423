@import "src/styles/variables"
@import "src/styles/breakpoints"

.placeholder
  font-weight: 700
  font-size: 20px
  text-shadow: var(--text-shadow-2)
  color: var(--wt-blue3-dt-white2)
  border: none
  background-color: transparent
  box-shadow: none
  display: flex
  align-items: center
  padding: 8px 10px
  margin: -8px -10px
  outline: none
  > svg
    width: 20px
    height: auto
    margin-left: 10px

.wrapper
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100vh
  z-index: 30
  background-color: var(--wt-white2-dt-black2)
  box-sizing: border-box
  transform: translateX(-100%)
  transition: transform $animationTime
  overflow-y: scroll
  -ms-overflow-style: none
  scrollbar-width: none
  &::-webkit-scrollbar
    display: none
  &.active
    transform: translateX(0)

.wrapperInner
  padding: 25px 16px
  overflow-y: scroll
  height: 100%

.heading
  width: 100%
  max-width: 340px
  margin-left: auto
  margin-right: auto
  margin-bottom: 40px
  display: flex
  align-items: center
  > div
    width: 33.333%
.headingButton
  width: 36px
  height: 36px
  svg
    width: 17px
    height: auto
    > path
      fill: var(--wt-blue3-dt-blue1)
.headingMiddle
  font-size: 24px
  font-weight: 700
  display: flex
  justify-content: center
  align-items: center
  text-shadow: var(--text-shadow-2)
  >svg
    margin-left: 10px
.headingReset
  text-align: right
  font-size: 18px
  height: 100%
  border: none
  box-shadow: none
  background-color: transparent
  outline: none
  padding: 8px 10px
  margin: -6px -10px -8px auto
  color: var(--wt-blue3-dt-white2)

.container
  display: flex
  flex-direction: column
  width: 100%
  align-items: center

.select
  width: 100%
  margin-bottom: 20px
  max-width: 340px


.button
  height: 43px
  display: flex
  width: 100%
  max-width: 340px
  margin-top: 10px