@import "../../styles/variables"
@import "../../styles/breakpoints"

.container
  &.errorLabel
    .error
      max-height: 50px
      margin-top: 5px


.label
  margin-bottom: 10px
  font-size: 1.143rem
  font-weight: 600
  color: var(--wt-blue3-dt-white2)
  text-shadow: var(--text-shadow-2)

.labelPadding
  padding-left: 10px

.option
  color: var(--wt-blue3-dt-white2)
  padding: 15px
  text-overflow: ellipsis
  overflow-x: hidden
  box-sizing: border-box
  &:hover:not(.optionSelect)
    background-color: var(--wt-white5-dt-black1)

.optionFocused
  // background-color: var(--wt-white5-dt-blue1)

.optionSelect
  background-color: var(--wt-blue3-dt-blue1)
  color: var(--wt-white2-dt-white2)
  font-weight: bold

.select
  &.h46
    :global(.customSelect__control)
      height: 46px
  input
    outline: none !important
    max-width: 170px
    box-sizing: border-box
  :global(.customSelect__placeholder)
    color: var(--wt-gray1-dt-blue5)
  :global(.customSelect__input)
    color: var(--wt-blue3-dt-white2)
    white-space: nowrap
  :global(.customSelect__input)
    margin-left: -2px
  :global(.customSelect__value-container)
    padding: 0 0 0 15px
    color: var(--wt-blue3-dt-white2)
    flex-wrap: nowrap
    > div
      padding: 0
      margin: 0
    >span
      max-width: 212px
      text-overflow: ellipsis
      overflow-x: hidden
      box-sizing: border-box
      @include media('<=1560px')
        max-width: 165px
  :global(.customSelect__menu)
    background: var(--wt-white2-dt-black2)
    box-shadow: var(--box-shadow-5)
    overflow: hidden
    border-radius: var(--wt-15px-dt-8px)
    z-index: 15
    cursor: pointer
  :global(.customSelect__indicator-separator)
    display: none
  :global(.customSelect__control--menu-is-open .customSelect__indicator)
    svg
      transform: rotate(180deg)
  :global(.customSelect__indicator)
    padding: 0
    padding-right: 10px
    svg
      transition: transform $animationTime
      path
        fill: var(--wt-blue3-dt-blue12)
  :global(.customSelect__control)
    border: none !important
    border-color: transparent !important
    box-shadow: none
    position: relative
    z-index: 5
    background: var(--wt-white2-dt-black2)
    transition: none
    border-radius: var(--wt-15px-dt-8px)
    cursor: pointer
    min-height: 43px
    position: relative
    &::after
      content: ''
      position: absolute
      border-radius: var(--wt-15px-dt-8px)
      opacity: 1
      transition: opacity $animationTime
  :global(.customSelect__control--menu-is-open), :global(.customSelect__control--is-focused)
    cursor: default
  .filterSelect
    :global(.customSelect__control)
      &::after
        background: var(--wt-white2-dt-black2)
        border: 3px solid var(--wt-white2-dt-black2)
        box-sizing: border-box
        box-shadow: var(--box-shadow-6)
        top: 1px
        left: 1px
        right: 1px
        bottom: 1px
        z-index: -1
  .filterSelectSelected
    position: relative
    &::after
      content: ''
      position: absolute
      bottom: -1px
      top: -1px
      left: -1px
      right: -1px
      z-index: -1
      border-radius: var(--wt-15px-dt-8px)
      background: var(--border-19)
      box-shadow: var(--box-shadow-5)
  .filterSelectSuccess
    &::after
      background: var(--border-17)
  .filterSelectError
    &::after
      background: var(--wt-red1-dt-red3)

.error
  color: var(--wt-red1-dt-red2)
  position: relative
  z-index: 3
  max-height: 0
  transition: max-height $animationTime
  overflow: hidden
  display: inline-block
