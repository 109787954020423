.loaderWrapper
    height: 50%
    display: flex
    justify-content: center
    align-items: center
    margin-top: 10%
    svg
        height: 100%
        flex: 1
        will-change: scale
        transform: translateZ(0)
        animation: pulsem 1.2s ease infinite   

.loaderWrapperFullPage
  margin-top: 0
  flex: 1

@keyframes pulsem 
  0% 
    transform: translateZ(0) scale(0.95)
  5% 
    transform: translateZ(0) scale(1)
  39% 
    transform: translateZ(0) scale(0.85)
  45% 
    transform: translateZ(0) scale(0.97)
  60% 
    transform: translateZ(0) scale(0.95)
  100% 
    transform: translateZ(0) scale(0.9)