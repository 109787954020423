@import "src/styles/variables"
@import "src/styles/breakpoints"

.resultsInfo
  font-weight: normal
  font-size: 12px
  margin: 30px 0 15px 0
  color: var(--wt-gray1-dt-blue5)

@include media('<=Lphone')
  .resultsInfo
    margin-top: 15px