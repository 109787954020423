@import "src/styles/variables"
@import "src/styles/breakpoints"

.pagination
  display: flex
  flex-direction: row
  align-items: center
  margin-top: 40px
  @include media('<=1200px')
    margin-top: 25px
  @include media('<=tablet')
    justify-content: center

.paginationItem
    padding: 12px
    cursor: pointer
    margin: 0 5px

.paginationItemContent
  z-index: 3
  background: var(--wt-white2-dt-black2)
  border-radius: 5px
  padding: 10px 15px

.paginationItemSelected
  position: relative
  &::before
    content: ''
    position: absolute
    top: -1px
    left: -1px
    right: -1px
    bottom: -1px
    background: var(--border-7)
    box-shadow: var(--box-shadow-5)
    z-index: -1
    border-radius: 5px

.rightArrowContent, .leftArrowContent
  cursor: pointer
  display: flex
  align-items: center
  p
    color: var(--wt-blue3-dt-white2)
    transition: all .1s
    font-weight: 500
    font-size: 14px
    line-height: 1
    margin: 0
    display: none
    @include media('<=tablet')
      display: block


.leftArrowContainer
  margin-right: 35px
  p
    margin-left: 7px

.rightArrowContainer
  margin-left: 35px
  p
    margin-right: 7px

@include media('<=tablet')
  .leftArrowContainer, .rightArrowContainer
    position: relative
    &::before
      content: ''
      position: absolute
      top: -1px
      left: -1px
      right: -1px
      bottom: -1px
      background: var(--border-7)
      box-shadow: var(--box-shadow-5)
      z-index: -1
      border-radius: 5px

  .leftArrowContainer
    margin-right: 7px
  .rightArrowContainer
    margin-left: 7px

  .rightArrowContent, .leftArrowContent
    padding: 10px 15px
    background: var(--wt-white2-dt-black2)
    border-radius: 5px
    z-index: 2