@import "src/styles/variables"
@import "src/styles/breakpoints"

.itemBrand
  text-decoration: none

.brandlink
  z-index: 20
  position: relative

.cardContent
  width: 100%

.content
  border-radius: 100%
  padding: 25px 25px 20px 25px
  display: flex
  flex-direction: row

.leftSide
  display: flex

.userImgPlaceholder
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  align-items: center

.descriptionWrapper
  padding: 0 0 0 10px
  width: 100%

.bottom
  display: flex
  justify-content: space-between

.img
  height: 142px
  width: 163px
  min-width: 163px
  margin: 0 10px 0 0
  background: #ffff
  box-shadow: var(--box-shadow-16)
  border-radius: 20px
  overflow: hidden
  > img
    width: 100%
    height: 100%
    object-fit: cover

.itemName
  color: var(--wt-blue3-dt-white2)
  font-weight: bold
  font-size: 1.143rem
  text-shadow: var(--text-shadow-2)
  margin: -5px 0 0 0

.details
  display: flex
  flex-direction: row
  justify-content: space-between
  flex-grow: 1
  margin-right: 60px

.detailsBlock
  min-width: 200px
  position: relative

.detailsItem
  display: flex,
  flex-direction: row,
  justify-content: space-between,
  padding: 3px 0 0 0
  margin: 0 10px 0 0
  color: var(--wt-blue3-dt-white2)
  > span:last-of-type
    text-align: right
  a
    color: var(--wt-blue3-dt-blue1)
    text-decoration: none
    font-weight: 600
  span
    background: none !important

.detailsDescription
  font-family: Gilroy
  font-style: normal
  font-weight: 600
  font-size: 1rem
  color: var(--wt-blue3-dt-white2)
  text-shadow: var(--text-shadow-2)
  padding: 0 35px 0 0
  white-space: nowrap

.detailsBlockRight
  margin: 0 0 0 40px
  max-width: 360px
  flex-grow: 1

.statisticBlock
  display: flex,
  flex-direction: row
  justify-content: flex-end
  margin-right: 45px

.statistic
  display: flex,
  align-items: center,
  flex-direction: column,
  justify-content: center,

.statisticValue
  text-transform: capitalize
  color: var(--wt-blue3-dt-white2),
  font-family: Gilroy,
  font-style: normal,
  font-weight: 600,
  font-size: 1.714rem
  text-align: center
  width: 100%
  display: block
  text-shadow: var(--text-shadow-2)
  white-space: nowrap

.procesWrapper
  display: flex
  flex-direction: column

.soldBefore
  color: var(--wt-blue3-dt-white2),
  font-family: Gilroy,
  font-style: normal,
  font-weight: 600,
  font-size: 1.2rem
  text-align: center
  width: 100%
  display: block
  text-shadow: var(--text-shadow-2)
  position: relative
  padding-top: 5px
  margin-top: 5px
  white-space: nowrap
  &::before
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 1px
    background-color: $text-dark
    opacity: .08

.statisticDescription
  text-align: center
  font-family: Gilroy,
  font-style: normal,
  font-weight: 600,
  font-size: 1.714rem
  padding: 5px 0 0 0
  color: var(--wt-blue3-dt-white2)
  text-shadow: var(--text-shadow-2)
  white-space: nowrap

.itemHashBlock
  margin: 0 0 0 15px
  padding: 2.5px 15px
  border-radius: 100px
  display: flex
  background: var(--wt-blue3-dt-blue1)
  box-shadow: var(--box-shadow-20)
  border: var(--border-10)
  z-index: 4
  position: relative

.itemHash
  color: $menu
  font-weight: 600
  font-size: 0.857rem
  white-space: nowrap

.titleBlock
  display: flex
  flex-direction: row
  align-items: center

.prices
  display: flex

.pricesTitles
  display: flex
  flex-direction: column
  margin-right: 15px
  align-items: flex-start

.showMore
  border: none
  background: transparent
  box-shadow: none
  padding: 10px
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  outline: none
  flex-direction: column
  color: var(--wt-gray1-dt-blue5)
  position: absolute
  left: 50%
  right: 50%
  transform: translateX(-50%)
  white-space: nowrap
  bottom: -53px
  z-index: 10
  svg
    margin-top: 5px
    transition: transform $animationTime
  &.active
    svg
      transform: rotate(-180deg)

.pricesValues
  display: flex
  flex-direction: column
  align-items: flex-end
//@media screen and (max-width:1200px)
//  .statisticValue
//    font-size: 1rem
//  .statisticDescription
//    font-size: 1rem
//  .detailsDescription
//    padding: 0 15px 0 0

@include media('<=1320px')
  .details
    flex-direction: column
    margin-right: 40px
  .detailsBlockRight
    margin-left: 0
    max-width: none

@include media('<=1100px')
  .details
    margin-right: 20px

@include media('<=tablet')
  .statisticBlock
    padding-left: 0
    justify-content: flex-start
    margin-top: 15px
  .statistic
    align-items: flex-start
    justify-content: space-between
    width: 100%
  .statisticValue
    text-align: start
  .soldBefore
    margin-top: 0
    padding-top: 0
    &::before
      content: none
  .bottom
    flex-direction: column

@include media('<=Lphone')
  .itemName
    margin-right: auto
  .titleBlock
    flex-direction: column
  .itemHashBlock
    margin-top: 15px
  .content
    flex-direction: column
  .img
    margin-right: 0
  .descriptionWrapper
    padding-left: 0
    margin-top: 30px
  .statisticBlock
    order: -1
    margin-top: 0
  .details
    padding-top: 25px
    margin-right: 0
  .statisticDescription
    padding-top: 0
  .bottom
    margin-top: 20px
  .img
    height: 180px
    width: 200px
    min-width: 200px

@include media('<=phone')
  .content
    padding: 20px
  .details
    padding-top: 10px
  .img
    width: 100%
  .mobileColumn
    flex-direction: column
    > span
      &:last-of-type
        text-align: left
  .soldBefore
    margin-top: 5px
    padding-top: 5px
    &::before
      content: ""
  .statistic
    align-items: flex-start
    flex-direction: column
    justify-content: space-between
    width: 100%
  .procesWrapper
    width: 100%
  .prices
    justify-content: center
  .statisticValue
    flex: 1
    text-align: center