@import "src/styles/variables"
@import "src/styles/breakpoints"

.cart
    background: var(--wt-white2-dt-black2)
    box-shadow: var(--box-shadow-16)
    border-radius: 20px
    padding: 10px 35px
    display: flex
    justify-content: space-between
    margin-bottom: 30px

.imgBlock
  width: 72px
  height: 63px
  background: var(--wt-white2-dt-black2)
  box-shadow: var(--box-shadow-16)
  border-radius: 10px
  border: 1px solid var(--wt-white4-dt-transparent)
  &:not(:last-of-type)
    margin-right: 10px

.imgInner
  width: 100%
  height: 100%
  border-radius: 10px
  overflow: hidden
  > img
    width: 100%
    height: 100%
    object-fit: cover


.imgPlaceholder
  display: flex
  justify-content: center
  align-items: center
  height: 100%
  > svg
    height: 60%
    width: auto

.leftBlock
    display: flex
    align-items: center

.rightBlock
    display: flex
    align-items: center

.textBlock
    margin-right: 50px

.label
    font-size: 1.429rem
    font-weight: 600
    color: var(--wt-blue3-dt-white2)
    text-shadow: var(--text-shadow-1)

.subLabel
    font-weight: 600
    font-size: 1rem
    line-height: 1.8
    color: var(--wt-blue3-dt-white2)
    text-shadow: var(--text-shadow-1)

.labelText
    color: #0b7be1

.buttonText
    padding: 0 35px

.iconWrapper
    width: 36px
    height: 36px
    display: flex
    justify-content: center
    align-items: center

.iconBtnWrapper
    padding-left: 32px

@include media('<=desktop')
  .cart
    padding: 10px 20px
  .textBlock
    margin-right: 20px

@include media('<=tablet')
  .cart
    padding: 20px

@include media('<=Lphone')
  .cart
    padding: 25px 20px
    flex-direction: column
  .textBlock
    margin-right: 0
    width: 100%
    margin-bottom: 20px

@include media('<=phone')
  .rightBlock
    width: 100%
    >div
      width: 100%
